/* eslint-disable */
var appointmentTypeId, formId, formRule, reschedule = false;
var consultationForm1, consultationForm2, consultationForm3;
var datepicker;

/* EVENTS */
datepicker = $('#appointment_date').datepicker({
	format: 'yyyy-mm-dd',
	startDate: '+3d',
    endDate: '+6m',
	daysOfWeekDisabled: [0], // Sun,Sat : 0,6
	maxViewMode: 'days',
	keyboardNavigation: false,
}).on('changeDate',function (e){
	fetchAvailableTime(e);
}).on('changeMonth',function (e){
	fetchAvailableDate(e.date);
});


$('#preferred_language').change(function(e){
	appointmentTypeId = null;
	datepicker.val("").datepicker("update");
	$("#appointment_time option").not('[disabled]').remove();
	$('#appointment_time').prop('selectedIndex', 0);
	fetchAvailableDate(new Date());
});


/* BEGIN */
function consultationSpecific() {
    var url = apipath + 'consultation/specific';

	$.ajax({
		url: url,
		type: 'GET',
		headers: { Authorization: 'Bearer ' + getCookie('ty_access_token'), 'X-localization': localization },
		contentType: 'application/json', // tell jQuery not to set contentType
        success : function(data) {
            log(data);

            for(var i = 0; i < data.length; i++) {
            	$("#name_list").append( new Option(data[i]['full_name'], data[i]['id']) );
            }

            $('#name_list option:eq(1)').prop('selected', true);
        },
        error : function(data) {
            var response = JSON.parse(data.responseText);
            handleError(response);
		},
		complete: function(data) {
			// log(data);
			removeLoading();
		}
    });
}

function stepOneConsultation() {
	consultationForm1 = $('.book-a-consultation-step-1').validate({
		debug: true,
		focusInvalid: true,
		rules: {
			name_list: {
				required: true
			},
		},
        errorPlacement: function(error, element) {
			error.insertAfter(element);
		},
		invalidHandler: function(e, validator) {
			// for (var i in validator.errorMap) {log(i, ':', validator.errorMap[i]); }
		},
        submitHandler: function (form) {
            var data = {
            	step : 1,
            	user_id : $( "#name_list" ).val(),
            };
            var url = apipath + 'consultation/steps';
        	log(data);

			loading();

            $.ajax({
                url: url,
				type: 'POST',
				headers: { Authorization: 'Bearer ' + getCookie('ty_access_token'), 'X-localization': localization },
				data: JSON.stringify(data),
				contentType: 'application/json', // tell jQuery not to set contentType
                success : function(data) {
                    // log(data);
                    if (data.message == 'scheduled_ongoing') {
						showSummaryPage(data);
					} else {
	                    showTwoConsultation();
					}
                },
                error : function(data) {
                    var response = JSON.parse(data.responseText);
					removeLoading();
					handleError(response);
                },
                complete : function(data) {
                    // log(data);
                    // removeLoading();
                }
            });
        }
    });
}

function stepTwoConsultation() {
	consultationForm2 = $('.book-a-consultation-step-2').validate({
		debug: true,
		focusInvalid: true,
		rules: {
			preferred_language: {
				required: true
			},
			appointment_date: {
				required: true
			},
			appointment_time: {
				required: true
			},
		},
        errorPlacement: function(error, element) {
			error.insertAfter(element);
		},
		invalidHandler: function(e, validator) {
			// for (var i in validator.errorMap) {log(i, ':', validator.errorMap[i]); }
		},
        submitHandler: function (form) {
            var data = {
            	step : 2,
            	user_id : $( "#name_list" ).val(),
            	appointment_type_id : appointmentTypeId,
            	preferred_language : $( "#preferred_language" ).val(),
            	appointment_date : $( "#appointment_date" ).val(),
            	appointment_time : $( "#appointment_time" ).val(),
            	reschedule : reschedule,
            };
            var url = apipath + 'consultation/steps';
        	log(data);

			loading();

            $.ajax({
                url: url,
				type: 'POST',
				headers: { Authorization: 'Bearer ' + getCookie('ty_access_token'), 'X-localization': localization },
				data: JSON.stringify(data),
				contentType: 'application/json', // tell jQuery not to set contentType
                success : function(data) {
                    log(data);
                    fetchFormDetails(data);
                },
                error : function(data) {
                    var response = JSON.parse(data.responseText);
					removeLoading();
					handleError(response);
                },
                complete : function(data) {
                    // log(data);
                    // removeLoading();
                }
            });
        }
    });
}

function stepThreeConsultation() {
	consultationForm3 = $('.book-a-consultation-step-3').validate({
		debug: true,
		focusInvalid: true,
		rules: formRule,
        errorPlacement: function(error, element) {
        	console.log($(element).parents('li'));
			// error.insertAfter($(element).parents('li'));
			// error.append($(element).parents('li'));
			$(element).parents('li').append(error);
		},
		invalidHandler: function(e, validator) {
			// for (var i in validator.errorMap) {log(i, ':', validator.errorMap[i]); }
		},
        submitHandler: function (form) {
        	var formAnswers = [];

			for(field in formRule) {
				console.log(field);

				if(formRule[field]['type'] == 'checkboxlist' || formRule[field]['type'] == 'yesno') {
					var currentInput = $('.book-a-consultation-step-3 input[name="'+field+'"]:checked');
				} else {
					var currentInput = $('.book-a-consultation-step-3 input[name="'+field+'"]');
				}

				formAnswers.push({
					id: currentInput.attr('data-field-id'),
					question: currentInput.attr('data-question'),
					value: currentInput.val(),
					type: formRule[field]['type'],
				});
			}

            var data = {
            	step : 3,
            	user_id : $( "#name_list" ).val(),
            	appointment_type_id : appointmentTypeId,
            	form_id : formId,
            	form_answers : formAnswers,
            	reschedule : reschedule,
            };

            var url = apipath + 'consultation/steps';

        	log(data);

			loading();

            $.ajax({
                url: url,
				type: 'POST',
				headers: { Authorization: 'Bearer ' + getCookie('ty_access_token'), 'X-localization': localization },
				data: JSON.stringify(data),
				contentType: 'application/json', // tell jQuery not to set contentType
                success : function(data) {
                    log(data);
                    showFourConsultation();

                    var language = data.data.language;
                    var temp_language = data.data.temp_language;

                    if (localization == "en"){

                        if (language == "English")
                            var print_lang = "English";
                        else if (language == "Malay")
                            var print_lang = "Malay";
                        else if (language == "Chinese")
                            var print_lang = "Chinese";

                        if (temp_language == "English")
                            var print_temp_lang = "English";
                        else if (temp_language == "Malay")
                            var print_temp_lang = "Malay";
                        else if (temp_language == "Chinese")
                            var print_temp_lang = "Chinese";

                    }else if (localization == "ms"){

                        if (language == "English")
                            var print_lang = "Bahasa Inggeris";
                        else if (language == "Malay")
                            var print_lang = "Bahasa Melayu";
                        else if (language == "Chinese")
                            var print_lang = "Bahasa Cina";

                        if (temp_language == "English")
                            var print_temp_lang = "Bahasa Inggeris";
                        else if (temp_language == "Malay")
                            var print_temp_lang = "Bahasa Melayu";
                        else if (temp_language == "Chinese")
                            var print_temp_lang = "Bahasa Cina";

                    }else if (localization == "zh"){

                        if (language == "English")
                            var print_lang = "英语";
                        else if (language == "Malay")
                            var print_lang = "国文";
                        else if (language == "Chinese")
                            var print_lang = "中文";

                        if (temp_language == "English")
                            var print_temp_lang = "英语";
                        else if (temp_language == "Malay")
                            var print_temp_lang = "国文";
                        else if (temp_language == "Chinese")
                            var print_temp_lang = "中文";
                    }

                    if(!reschedule) {
						var datetimeArray = (data.data.datetime).split(' ');
						$('#print-language').text(print_lang);
						$('#print-date').text(datetimeArray[0]);
						$('#print-timeslot').text(convertTimeToReadable12(new Date(data.data.datetime)));
                    } else {
						var datetimeArray = (data.data.temp_datetime).split(' ');
						$('#print-language').text(print_temp_lang);
						$('#print-date').text(datetimeArray[0]);
						$('#print-timeslot').text(convertTimeToReadable12(new Date(data.data.temp_datetime)));
                    }
                },
                error : function(data) {
                    var response = JSON.parse(data.responseText);
					removeLoading();
					handleError(response);
                },
                complete : function(data) {
                    // log(data);
                    // removeLoading();
                }
            });
        }
    });
}

function stepFourConsultation() {
	var data = {
    	step : 4,
    	user_id : $( "#name_list" ).val(),
    	reschedule : reschedule,
    };
    var url = apipath + 'consultation/steps';
	log(data);

	loading();

    $.ajax({
        url: url,
		type: 'POST',
		headers: { Authorization: 'Bearer ' + getCookie('ty_access_token'), 'X-localization': localization },
		data: JSON.stringify(data),
		contentType: 'application/json', // tell jQuery not to set contentType
        success : function(data) {
            log(data);
            resetForm();
            showSummaryPage(data);
            reschedule = false;
        },
        error : function(data) {
            var response = JSON.parse(data.responseText);
        	removeLoading();
			handleError(response);
        },
        complete : function(data) {
            // log(data);
            // removeLoading();
        }
    });
}

/* BACK */
function showTwoConsultation() {
	$('#preferred_language option:eq(1)').prop('selected', true);
	$('.book-a-consultation-step-1').css('display', 'none');
	$('.book-a-consultation-step-2').css('display', 'block');
	$('.book-a-consultation-step-3').css('display', 'none');
	$('.book-a-consultation-step-4').css('display', 'none');
	$('.step1').addClass('completed').removeClass('checked');
	$('.step2').addClass('checked').removeClass('uncheck completed');
	$('.step3').addClass('uncheck').removeClass('checked completed');
	$('.step4').addClass('uncheck').removeClass('checked completed');
	$('.book-a-consultation .progress-status').removeClass('hide');
	removeLoading();
	fetchAvailableDate(new Date());
}

function showThreeConsultation() {
	$('.book-a-consultation-step-1').css('display', 'none');
	$('.book-a-consultation-step-2').css('display', 'none');
	$('.book-a-consultation-step-3').css('display', 'block');
	$('.book-a-consultation-step-4').css('display', 'none');
	$('.step1').addClass('completed').removeClass('checked');
	$('.step2').addClass('completed').removeClass('checked');
	$('.step3').addClass('checked').removeClass('uncheck completed');
	$('.step4').addClass('uncheck').removeClass('checked completed');
}

function showFourConsultation() {
	$('.book-a-consultation-step-1').css('display', 'none');
	$('.book-a-consultation-step-2').css('display', 'none');
	$('.book-a-consultation-step-3').css('display', 'none');
	$('.book-a-consultation-step-4').css('display', 'block');
	$('.step1').addClass('completed').removeClass('checked');
	$('.step2').addClass('completed').removeClass('checked');
	$('.step3').addClass('completed').removeClass('checked');
	$('.step4').addClass('checked').removeClass('uncheck completed');
	removeLoading();
}

function stepTwoBackConsultation() {
	if(!reschedule) {
		$('.book-a-consultation-step-2').css('display', 'none');
		$('.book-a-consultation-step-1').css('display', 'block');
		$('.step2').addClass('uncheck').removeClass('checked');
		$('.step1').addClass('checked').removeClass('completed');
		$('.book-a-consultation .progress-status').addClass('hide');
	} else {
		if (localization == 'en') {
			errorTitle = 'Are you sure?';
			errorMsg = 'Heading back means your changes will be discarded.';
			buttontext = 'Yes, discard my changes!';
		} else if (localization == 'ms') {
			errorTitle = 'Adakah anda pasti?';
			errorMsg = 'Jika anda kembali, sebarang perubahan anda akan dibatalkan';
			buttontext = 'Ya, batalkan perubahan saya!';
		} else if (localization == 'zh') {
			errorTitle = '您确定吗？';
			errorMsg = '一旦返回您的修改就会被取消。';
			buttontext = '是的，取消我的修改!';
        }

		Swal.fire({
            title: errorTitle,
            text: errorMsg,
            type: 'warning', animation: false,
            customClass: {
                popup: 'animated fadeIn'
            },
            showCancelButton: true,
            confirmButtonColor: '#FF0000',
            confirmButtonText: buttontext
		}).then(function(results) {
			log(results);
			if(results.value) {
				reschedule = false;
            	resetForm();
				$('.book-a-consultation-step-2').css('display', 'none');
				$('.book-a-consultation-step-1').css('display', 'block');
				$('.step2').addClass('uncheck').removeClass('checked');
				$('.step1').addClass('checked').removeClass('completed');
            	$('#consultation-booking-title, #consultation-booking-desc').removeClass('hide');
				$('#consultation-reschedule-title, #consultation-reschedule-desc').addClass('hide');
				$('.book-a-consultation .progress-status').addClass('hide');
			}
		});
	}
}

function stepThreeBackConsultation() {
	$('.book-a-consultation-step-3').css('display', 'none');
	$('.book-a-consultation-step-2').css('display', 'block');
	$('.step3').addClass('uncheck').removeClass('checked');
	$('.step2').addClass('checked').removeClass('completed');
}

function stepFourBackConsultation() {
	$('.book-a-consultation-step-4').css('display', 'none');
	$('.book-a-consultation-step-3').css('display', 'block');
	$('.step4').addClass('uncheck').removeClass('checked');
	$('.step3').addClass('checked').removeClass('completed');
}

function summaryBack() {
	$('.book-a-consultation-summary').css('display', 'none');
	$('.book-a-consultation-step-1').css('display', 'block');
	$('.step4').addClass('uncheck').removeClass('checked completed');
	$('.step3').addClass('uncheck').removeClass('checked completed');
	$('.step2').addClass('uncheck').removeClass('checked completed');
	$('.step1').addClass('checked').removeClass('completed');
	$('#consultation-booking-title, #consultation-booking-desc').removeClass('hide');
	$('#consultation-reschedule-title, #consultation-reschedule-desc').addClass('hide');
	$('#consultation-summary-title, #consultation-summary-desc').addClass('hide');
	// $('.book-a-consultation .progress-status').removeClass('hide');
}

/* EVENTS FUNCTIONS */
function fetchAvailableDate(date) {
	console.log('fetchAvailableDate', date);
	var month = ('0' + (date.getMonth() + 1)).substr(-2, 2);
	var year = date.getFullYear();

	var data = {
    	appointment_date : year + '-' + month,
    	preferred_language : $( "#preferred_language" ).val(),
    };
    if(appointmentTypeId) data['appointment_type_id'] = appointmentTypeId;
    var url = apipath + 'consultation/date';
	log(data);

	loading();

	$.ajax({
        url: url,
		type: 'POST',
		headers: { Authorization: 'Bearer ' + getCookie('ty_access_token'), 'X-localization': localization },
		data: JSON.stringify(data),
		contentType: 'application/json', // tell jQuery not to set contentType
        success : function(data) {
            log(data);
            appointmentTypeId = data['appointment_type_id'];

            var totalDays = [];
            var availableDates = [];
            var disabledDates = [];
            var numOfDays = new Date(year, Number(month) - 1, 0).getDate(); // Jan = 31, Feb = 28/29

			for(var i = 0; i < numOfDays; i++) {
				var date = new Date(year, Number(month) - 1, i + 1);
				totalDays[i] = convertDateToReadable(date);
			}

			for(var i = 0; i < data['list'].length; i++) {
				availableDates.push(data['list'][i]['date']);
			}

			for(var i = 0; i < totalDays.length; i++) {
				if(!availableDates.includes(totalDays[i]))
					disabledDates.push(totalDays[i]);
			}

			console.log(totalDays, availableDates, disabledDates);

            datepicker.datepicker('setDatesDisabled', disabledDates);
            datepicker.datepicker('update', year+'-'+month+'-01');
            // datepicker.datepicker('clearDate');
            $('#appointment_date').val('');
            $("#appointment_time option").not('[disabled]').remove();
            $('#appointment_time').prop('selectedIndex', 0);
        },
        error : function(data) {
            var response = JSON.parse(data.responseText);
            handleError(response);
        },
        complete : function(data) {
            // log(data);
            removeLoading();
        }
    });
}

function fetchAvailableTime(e) {
	console.log('fetchAvailableTime', e);

	var data = {
    	date : $('#appointment_date').val(),
    	preferred_language : $( "#preferred_language" ).val(),
    };

    if(appointmentTypeId)
        data['appointment_type_id'] = appointmentTypeId;

    var url = apipath + 'consultation/time';
	log(data);

	loading();

	$.ajax({
        url: url,
		type: 'POST',
		headers: { Authorization: 'Bearer ' + getCookie('ty_access_token'), 'X-localization': localization },
		data: JSON.stringify(data),
		contentType: 'application/json', // tell jQuery not to set contentType
        success : function(data) {
            log(data);
            appointmentTypeId = data['appointment_type_id'];
            $("#appointment_time option").not('[disabled]').remove();
            $('#appointment_time').prop('selectedIndex', 0);
            $('#appointment_date').removeClass('error').attr('aria-invalid', false);
            $('#appointment_date-error').remove();
            for(var i = 0; i < data['list'].length; i++) {
            	var d = new Date(data['list'][i]['time']);
            	var date = convertDateToReadable(d);
            	var time = convertTimeToReadable24(d);
            	var timeString = convertTimeToReadable12(d);
            	$("#appointment_time").append( new Option(timeString, time) );
            }
        },
        error : function(data) {
            var response = JSON.parse(data.responseText);
			handleError(response);
        },
        complete : function(data) {
            // log(data);
            removeLoading();
        }
    });
}

function fetchFormDetails(prevData) {
	var data = {
    	appointment_type_id : appointmentTypeId,
    };
    var url = apipath + 'consultation/form';
	log(data);

	loading();

	 $.ajax({
        url: url,
		type: 'POST',
		headers: { Authorization: 'Bearer ' + getCookie('ty_access_token'), 'X-localization': localization },
		data: JSON.stringify(data),
		contentType: 'application/json', // tell jQuery not to set contentType
        success : function(data) {
            log(data);
            if(data['form']) {
            	formId = data['form']['id'];
            	formRule = {};
            	$(".book-a-consultation-step-3 ul").html('');
            	var listEl = '';

	            for(var i = 0; i < data['form']['fields'].length; i++) {
	            	var field = data['form']['fields'][i];
	            	var prevField = data['form']['fields'][i-1];
	            	var fieldId = field['id'];
	            	var fieldName = field['name'];
	            	var fieldType = field['type'];
                    var fieldNameArr = fieldName.split("|");

                    if (localization == "en") {
                        $specifyLang    = 'If yes, please specify';
                        $yesLang        = 'Yes';
                        $noLang         = 'No';
                        $fieldNameLang  = fieldNameArr[0];
                    } else if (localization == "ms") {
                        $specifyLang    = 'Jika Ya, sila nyatakan';
                        $yesLang        = 'Ya';
                        $noLang         = 'Tidak';
                        $fieldNameLang  = fieldNameArr[1];
                    } else if (localization == "zh") {
                        $specifyLang    = '如果是，请说明';
                        $yesLang        = '是';
                        $noLang         = '否';
                        $fieldNameLang  = fieldNameArr[2];
                    }

                    var questionEl = '<label>'+$fieldNameLang+'</label>';
                    var optionEl = '';

	            	switch(fieldType) {
            			case 'checkboxlist':
            				var options = field['options'];
	            			for(var k = 0; k < options.length; k++) {
                                var option = options[k];
                                var optionArr = option.split("|");

                                if (localization == "en") {
                                    $optionLang    = optionArr[0];
                                } else if (localization == "ms") {
                                    $optionLang    = optionArr[1];
                                } else if (localization == "zh") {
                                    $optionLang    = optionArr[2];
                                }

			            		optionEl += '\
			            		<label for="q'+i+'_a'+k+'">\
			                		<input type="radio" id="q'+i+'_a'+k+'" name="q'+i+'" data-field-id="'+fieldId+'" data-question="'+fieldName+'" data-type="'+fieldType+'" value="'+option+'">\
			                		<span class="checkmark"></span>\
									'+$optionLang+'\
								</label>';
			            	}
			            	formRule['q'+i] = {type: fieldType, depends: null};
            				break;

            			case 'yesno':
		            		optionEl += '\
		            		<label for="q'+i+'_a_yes">\
		                		<input type="radio" id="q'+i+'_a_yes" name="q'+i+'" data-field-id="'+fieldId+'" data-question="'+fieldName+'" data-type="'+fieldType+'" value="yes">\
		                		<span class="checkmark"></span> '+$yesLang+' \
							</label>\
		            		<label for="q'+i+'_a_no">\
		                		<input type="radio" id="q'+i+'_a_no" name="q'+i+'" data-field-id="'+fieldId+'" data-question="'+fieldName+'" data-type="'+fieldType+'" value="no">\
		                		<span class="checkmark"></span> '+$noLang+' \
							</label>\
							';
							formRule['q'+i] = {type: fieldType, depends: null};
            				break;

            			case 'textbox':

            				if(i!= 0 && prevField['type'] == 'yesno' && prevField['name'] == fieldName) {
            					questionEl = '';
			            		optionEl += $specifyLang+' <br><br><input type="text" id="q'+i+'" name="q'+i+'" data-field-id="'+fieldId+'" data-question="'+fieldName+'" data-type="'+fieldType+'">';
			            		formRule['q'+i] = {type: fieldType, depends: '#q'+(i-1)+'_a_yes'};
            				} else {
			            		optionEl += '<input type="text" id="q'+i+'" name="q'+i+'" data-field-id="'+fieldId+'" data-question="'+fieldName+'" data-type="'+fieldType+'">';
			            		formRule['q'+i] = {type: fieldType, depends: null};
            				}
            				break;
            		}

	            	listEl += '<li>'+questionEl+optionEl+'</li>';
	            }

            	$(".book-a-consultation-step-3 ul").append(listEl);

            	for(key in formRule) {
            		if(formRule[key]['depends']) {
						$('.book-a-consultation-step-3 input[name="'+key+'"]').rules('add', {required: {
							depends: function (element) {
								return $(element).parents('li').prev().find('[value=yes]').is(":checked");
							}
						}});
            		} else {
        				$('.book-a-consultation-step-3 input[name="'+key+'"]').rules('add', { required: true });
            		}
				}

				log('append list');

				showThreeConsultation();

				autoPopulateFormAnswers(prevData);

				removeLoading();
            }
        },
        error : function(data) {
            var response = JSON.parse(data.responseText);
			removeLoading();
			handleError(response);
        },
        complete : function(data) {
            // log(data);
            // removeLoading();
        }
    });
}

function summaryReschedule() {
	reschedule = true;
	showTwoConsultation();
	$('.book-a-consultation-summary').css('display', 'none');
	$('#consultation-summary-title, #consultation-summary-desc').addClass('hide');
	$('#consultation-reschedule-title, #consultation-reschedule-desc').removeClass('hide');
}

function showSummaryPage(data) {
	$('.book-a-consultation-step-1, .book-a-consultation-step-2, .book-a-consultation-step-3, .book-a-consultation-step-4').css('display', 'none');
	$('.step1, .step2, .step3, .step4').addClass('completed').removeClass('checked');
	$('.book-a-consultation-summary').css('display', 'block');

	$('#consultation-booking-title, #consultation-booking-desc').addClass('hide');
	$('#consultation-reschedule-title, #consultation-reschedule-desc').addClass('hide');
	$('#consultation-summary-title, #consultation-summary-desc').removeClass('hide');

	// hide steps
	$('.book-a-consultation .progress-status').addClass('hide');

	var newData;

	if(data.data) newData = data.data;
	else newData = data;

    var datetimeArray = (newData.datetime).split(' ');
    var languageData  = newData.language;

    if (localization == "en"){
        if (languageData == "English")
            var print_lang = "English";
        else if (languageData == "Malay")
            var print_lang = "Malay";
        else if (languageData == "Chinese")
            var print_lang = "Chinese";
    }else if (localization == "ms"){
        if (languageData == "English")
            var print_lang = "Bahasa Inggeris";
        else if (languageData == "Malay")
            var print_lang = "Bahasa Melayu";
        else if (languageData == "Chinese")
            var print_lang = "Bahasa Cina";
    }else if (localization == "zh"){
        if (languageData == "English")
            var print_lang = "英语";
        else if (languageData == "Malay")
            var print_lang = "国文";
        else if (languageData == "Chinese")
            var print_lang = "中文";
    }

	$('#print-summary-name').text(newData.full_name);
	$('#print-summary-language').text(print_lang);
	$('#print-summary-date').text(datetimeArray[0]);
	$('#print-summary-time').text(convertTimeToReadable12(new Date(newData.datetime)));
	$('#print-summary-duration').text(newData.duration + durationLocalization());
	$('#print-summary-consultant').text(newData.calendar);
	$('#print-summary-scheduled-by').text(newData.scheduled_by);

	var formAnswersEl = '';

	var formAnswers = typeof newData.form_answers == 'string' ? JSON.parse(newData.form_answers) : newData.form_answers;

	for(var i = 0; i < formAnswers.length; i++) {
		log(formAnswers[i]);
		formAnswersEl += '\
		<li>\
			<div class="small-12 columns"><label>'+formAnswers[i]['question']+'</label></div>\
			<div class="small-12 columns">'+formAnswers[i]['value']+'</div>\
		</li>';
	}
	log(formAnswersEl);

    $('#print-summary-questionnaires').html(formAnswersEl);

    if (localization == 'en') {
        var case2Text   = 'Click the reschedule button if you wish to reschedule your consultation. You may only reschedule twice.';
        var case1Text   = 'Click the reschedule button if you wish to reschedule your consultation. You may only reschedule once.';
        var caseText    = 'You can no longer reschedule your appointment.';
    } else if (localization == 'ms') {
        var case2Text   = 'Klik butang Jadualkan Semula jika anda ingin menjadualkan semula perundingan anda. Anda hanya boleh menjadualkan semula dua kali.';
        var case1Text   = 'Klik butang Jadualkan Semula jika anda ingin menjadualkan semula perundingan anda. Anda hanya boleh menjadualkan semula satu kali.';
        var caseText    = 'Anda tidak boleh menjadualkan semula janji temu anda.';
    } else if (localization == 'zh') {
        var case2Text   = '如果您想重新预约咨询时间，请点击"改期"。 您只能改期两次。';
        var case1Text   = '如果您想重新预约咨询时间，请点击"改期"。 您只能改期一次。';
        var caseText    = '您不能再改期您的预约。';
    }

	switch(newData.reschedule_remaining) {
		case 2:
			$('#consultation-reschedule-desc span, #consultation-summary-desc span').text(case2Text);
			break;
		case 1:
			$('#consultation-reschedule-desc span, #consultation-summary-desc span').text(case1Text);
			break;
		default:
			$('#consultation-reschedule-desc span, #consultation-summary-desc span').text(caseText);
			break;
	}

	if(newData.reschedule_remaining > 0)
		$('#summary-reschedule-btn').removeClass('hide');
	else
		$('#summary-reschedule-btn').addClass('hide');

	removeLoading();
}

/* HELPER */
function convertDateToReadable(d) {
	return d.getFullYear() + '-' + ('0'+(d.getMonth()+1)).substr(-2, 2) + '-' + ('0'+d.getDate()).substr(-2, 2);
}

function convertTimeToReadable12(d) {
	return ('0'+(d.getHours() <= 12 ? d.getHours() : d.getHours() - 12)).substr(-2, 2) + ':' + ('0'+d.getMinutes()).substr(-2, 2) + (d.getHours() < 12 ? timeLocalization('am') : timeLocalization('pm'));
}

function convertTimeToReadable24(d) {
	return ('0'+d.getHours()).substr(-2, 2) + ':' + ('0'+d.getMinutes()).substr(-2, 2) + ':' + ('0'+d.getSeconds()).substr(-2, 2);
}

function timeLocalization(ampm) {
	if (localization == 'en') {
		return ampm == 'am' ? 'AM' : 'PM';
	} else if (localization == 'ms') {
		return ampm == 'am' ? 'AM' : 'PM';
	} else if (localization == 'zh') {
		return ampm == 'am' ? 'AM' : 'PM';
	}
}

function durationLocalization() {
	if (localization == 'en') {
		return ' mins';
	} else if (localization == 'ms') {
		return ' minit';
	} else if (localization == 'zh') {
		return ' 分钟';
	}
}

function handleError(response) {
	log('handleError');
	log(response);
	if (response.message == 'token_expired' || response.message == 'token_invalid') {
		promptTokenExpired();
		$('.navLoader').css('display', 'none');
	}
	else if (response.message == 'consultation_not_available') {

		if (localization == 'en') {
			errorTitle = 'Consultation Not Available';
			errorMsg = 'The testkit tied to this user does not contain any consultation feature.';
			buttontext = 'OK';
		} else if (localization == 'ms') {
			errorTitle = 'Perundingan Tiada';
			errorMsg = 'Kit ujian yang didaftarkan dengan pengguna ini tidak mempunyai perundingan.';
			buttontext = 'OK';
		} else if (localization == 'zh') {
			errorTitle = '不提供咨询';
			errorMsg = '用户绑定的检测套件不包含任何咨询服务。';
			buttontext = '好的';
		}

		Swal.fire({
            title: errorTitle,
            text: errorMsg,
            type: 'error',
            animation: false,
            customClass: {
                popup: 'animated fadeIn'
            },
            showCancelButton: false,
            confirmButtonColor: '#FF0000',
            confirmButtonText: buttontext
		}).then(function(results) {
			// window.location.href = redirectPath + '/login';
		});
	}
	else if (response.message == 'appointment_not_exist') {

		if (localization == 'en') {
			errorTitle = 'Oops!';
			errorMsg = 'Appointment does not exist!';
			buttontext = 'OK';
		} else if (localization == 'ms') {
			errorTitle = 'Oops!';
			errorMsg = 'Janji temu tidak wujud!';
			buttontext = 'OK';
		} else if (localization == 'zh') {
			errorTitle = 'Oops!';
			errorMsg = '预约不存在!';
			buttontext = '好的';
		}

        Swal.fire({
            title: errorTitle,
            text: errorMsg,
            type: 'error',
            animation: false,
            customClass: {
                popup: 'animated fadeIn'
            },
            showCancelButton: false,
            confirmButtonColor: '#FF0000',
            confirmButtonText: buttontext
		}).then(function(results) {
			window.location.href = redirectPath + '/book-a-consultation';
		});
	}
	else if (response.message == 'no_testkit_or_incomplete') {

		if (localization == 'en') {
			errorTitle = 'No Testkit Found / Incomplete Testkit';
			errorMsg = 'This user does not have a complete testkit.';
			buttontext = 'OK';
		} else if (localization == 'ms') {
			errorTitle = 'Tiada kit ujian ditemui / kit ujian tidak lengkap';
			errorMsg = 'Tiada kit ujian yang selesai untuk pengguna ini';
			buttontext = 'OK';
		} else if (localization == 'zh') {
			errorTitle = '没有找到检测套件 / 检测套件不完整';
			errorMsg = '用户没有完整的测试套件。';
			buttontext = '好的';
		}

        Swal.fire({
            title: errorTitle,
            text: errorMsg,
            type: 'error',
            animation: false,
            customClass: {
                popup: 'animated fadeIn'
            },
            showCancelButton: false,
            confirmButtonColor: '#FF0000',
            confirmButtonText: buttontext
		}).then(function(results) {
			// window.location.href = redirectPath + '/book-a-consultation';
		});
	}
	else if (response.message == 'pdf_not_ready') {

		if (localization == 'en') {
			errorTitle = 'Your report is on it\'s way.';
			errorMsg = 'Please come back later.';
			buttontext = 'OK';
		} else if (localization == 'ms') {
			errorTitle = 'Laporan anda sedang diproses.';
			errorMsg = 'Sila kembali sebentar lagi.';
			buttontext = 'OK';
		} else if (localization == 'zh') {
			errorTitle = '您的报告正在路上。';
			errorMsg = '请稍后再来。';
			buttontext = '好的';
		}

        Swal.fire({
            title: errorTitle,
            text: errorMsg,
            type: 'error',
            animation: false,
            customClass: {
                popup: 'animated fadeIn'
            },
            showCancelButton: false,
            confirmButtonColor: '#FF0000',
            confirmButtonText: buttontext
		}).then(function(results) {
			// window.location.href = redirectPath + '/book-a-consultation';
		});
	}
	else {
		Swal.fire({
			type: 'error',
			title: 'Error',
			text: response.message,
			animation: false,
			customClass: {
				popup: 'animated fadeIn'
			}
		});
	}
}

function resetForm() {
	consultationForm2.resetForm();
    consultationForm3.resetForm();
    $('#appointment_date').val('');
    $('#appointment_time').prop('selectedIndex', 0);
}

function autoPopulateFormAnswers(data) {
	log('autoPopulateFormAnswers');
	log(data);

	if(reschedule)
		var formAnswers = data.data.temp_form_answers ? data.data.temp_form_answers : data.data.form_answers;
	else
		var formAnswers = data.data.form_answers;

	if(formAnswers) {
		formAnswers = JSON.parse(formAnswers);
		for(var i = 0; i < formAnswers.length; i++) {
			log(currEl);
			if(formAnswers[i]['type'] == 'checkboxlist' || formAnswers[i]['type'] == 'yesno') {
				var currEl = $('.book-a-consultation-step-3 input[data-field-id='+formAnswers[i]['id']+'][value="'+formAnswers[i]['value']+'"]');
				currEl.prop('checked', true);
			} else {
				var currEl = $('.book-a-consultation-step-3 input[data-field-id='+formAnswers[i]['id']+']');
				currEl.val(formAnswers[i]['value'] || '');
			}
		}
	}
}


